<template>
	<div class="wrap">
		<div class="tab">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="店铺基本设置" name="0" />
				<el-tab-pane label="企业简介" name="1" />
				<el-tab-pane label="联系我们" name="2" />
				<el-tab-pane label="企业文化" name="3" />
				<el-tab-pane label="营销网络" name="4" />
				<el-tab-pane label="组织架构" name="5" />
			</el-tabs>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tabList: [],
				activeName: this.$route.name.charAt(this.$route.name.length - 1)
			}
		},
		methods: {
			handleClick(tab, event) {
				this.$router.push('/setShop/s' + this.activeName)
			}
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.tab {
		padding: 0 28px;
		border-bottom: 1px solid #ebebeb;
		overflow: hidden;

	}

	.tab>>>.el-tabs__item {
		height: 60px;
		line-height: 60px;
	}

	.tab>>>.el-tabs__header {
		margin: 18px 0 0 0;
	}

	.form {
		padding: 45px 28px 0;
		overflow: hidden;
	}

	.form>>>.el-form-item__label {
		color: #333;
	}

	.form>>>.el-input:not(.el-input--suffix) .el-input__inner {
		width: 600px;
	}

	.form>>>.el-textarea__inner {
		width: 600px;
	}

	.form>>>.submit-btn {
		width: 80px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		color: #fff;
		background: #0f69dc;
		margin: 110px auto;
		border-radius: 5px;
		cursor: pointer;
	}

	.center {
		margin-left: 69px;
	}

	.form>>>.subTit {
		font-size: 14px;
		color: #333333;
		margin: 0 0 37px 37px;
	}
</style>
